import React from 'react';
import styled from 'styled-components';

import { LoadingAnimation } from './loading-animation/LoadingAnimation';

interface ILoadingProps {}

const Page = styled.main`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Loading = (props: ILoadingProps) => (
  <Page {...props}>
    <LoadingAnimation />
  </Page>
);

export default Loading;
