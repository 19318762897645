import LogRocket from 'logrocket';
import { datadogLogs } from '@datadog/browser-logs';

export function init(): void {
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_STAGE === 'dev') {
    return;
  }

  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
  LogRocket.track(`Env: ${process.env.REACT_APP_STAGE}`);
  LogRocket.track(`Brand: ${process.env.REACT_APP_BRAND}`);

  LogRocket.getSessionURL((sessionUrl: string) => {
    datadogLogs.logger.addContext('logrocketSessionUrl', sessionUrl);
  });
}
