import React, { FC, ReactNode, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useBrand } from 'src/brand';
/*
 * No need to lazily load these as they're pretty small
 */
import BKThemeProvider from './Theme.bk';
import THThemeProvider from './Theme.th';
import PLKThemeProvider from './Theme.plk';
import 'react-toastify/dist/ReactToastify.min.css';

const themes = {
  bk: BKThemeProvider,
  th: THThemeProvider,
  plk: PLKThemeProvider,
} as const;

export const ThemeProvider: FC<{ children: ReactNode }> = (props) => {
  const brand = useBrand();
  const Provider = useMemo(() => themes[brand], [brand]);

  return (
    <>
      <Provider {...props} />
      <GlobalStyle />
    </>
  );
};

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
  }

  html, body, #root {
    margin: 0;
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
  }
`;

export type Theme = typeof import('./Theme.bk').theme;
