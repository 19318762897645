/*
  -----------------------------------------------------------
  WARNING: DO NOT MANUALLY MODIFY THIS FILE!
  FILE GENERATED USING THE MARKETS CLI SCRIPT.
  IF YOU NEED TO ADD, REMOVE OR MODIFY MARKETS PLEASE
  NAVIGATE TO PROJECT ROOT FOLDER AND RUN `yarn markets`.
  -----------------------------------------------------------
  */
export type Currency = "USD" | "GBP" | "CAD" | "CHF" | "EUR" | "ZAR";
export type RegionName = "United States Of America" | "Great Britain" | "Canada" | "Switzerland" | "Germany" | "South Africa";
export enum Regions {
    US = "US",
    GB = "GB",
    CA = "CA",
    CH = "CH",
    DE = "DE",
    ZA = "ZA"
}
export type RegionCode = "US" | "GB" | "CA" | "CH" | "DE" | "ZA";
export enum RegionNames {
    US = "United States Of America",
    GB = "Great Britain",
    CA = "Canada",
    CH = "Switzerland",
    DE = "Germany",
    ZA = "South Africa"
}
export enum RegionCurrencies {
    US = "USD",
    GB = "GBP",
    CA = "CAD",
    CH = "CHF",
    DE = "EUR",
    ZA = "ZAR"
}
export type Brands = "bk" | "plk" | "th";
export type LanguageCode = "en" | "fr" | "de";
export type LanguageName = "English" | "French" | "German";
export type LocaleCode = "en-US" | "en-GB" | "en-CA" | "fr-CA" | "en-CH" | "de-CH" | "de-DE" | "en-ZA";
export interface IMarket {
    regionCode: RegionCode;
    regionName: RegionName;
    regionCurrency: Currency;
    regionBrands: Brands[];
    regionLanguages: IMarketLanguage[];
}
export interface IMarketLanguage {
    languageCode: LanguageCode;
    languageLocale: LocaleCode;
    languageName: LanguageName;
}
export const MARKETS: IMarket[] = [{ regionCode: "US", regionName: "United States Of America", regionCurrency: "USD", regionBrands: ["bk"], regionLanguages: [{ languageCode: "en", languageLocale: "en-US", languageName: "English" }] }, { regionCode: "GB", regionName: "Great Britain", regionCurrency: "GBP", regionBrands: ["bk"], regionLanguages: [{ languageCode: "en", languageLocale: "en-GB", languageName: "English" }] }, { regionCode: "CA", regionName: "Canada", regionCurrency: "CAD", regionBrands: [], regionLanguages: [{ languageCode: "en", languageLocale: "en-CA", languageName: "English" }, { languageCode: "fr", languageLocale: "fr-CA", languageName: "French" }] }, { regionCode: "CH", regionName: "Switzerland", regionCurrency: "CHF", regionBrands: ["plk", "bk"], regionLanguages: [{ languageCode: "en", languageLocale: "en-CH", languageName: "English" }, { languageCode: "de", languageLocale: "de-CH", languageName: "German" }] }, { regionCode: "DE", regionName: "Germany", regionCurrency: "EUR", regionBrands: ["bk"], regionLanguages: [{ languageCode: "de", languageLocale: "de-DE", languageName: "German" }] }, { regionCode: "ZA", regionName: "South Africa", regionCurrency: "ZAR", regionBrands: ["bk"], regionLanguages: [{ languageCode: "en", languageLocale: "en-ZA", languageName: "English" }] }];
export const LOCALES: LocaleCode[] = ["en-US", "en-GB", "en-CA", "fr-CA", "en-CH", "de-CH", "de-DE", "en-ZA"];
export const LANGUAGE_CODES: LanguageCode[] = ["en", "fr", "de"];
export const CURRENCIES: Currency[] = ["USD", "GBP", "CAD", "CHF", "EUR", "ZAR"];
export const REGIONS: RegionCode[] = ["US", "GB", "CA", "CH", "DE", "ZA"];
export const REGION_NAMES: RegionName[] = ["United States Of America", "Great Britain", "Canada", "Switzerland", "Germany", "South Africa"];
export const BRANDS: Brands[] = ["bk", "plk", "th"];
