import React, { FC, useMemo } from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import { useFormik, useFormikContext, useField, FormikProvider } from 'formik';

import { Select } from 'src/components';
import { useLocale, supportedLanguagesByRegion } from 'src/locale';
import { useBrand, withBrandTag } from 'src/brand';
import BKLogo from 'src/assets/logos/bk-logo.svg';
import THLogo from 'src/assets/logos/th-wordmark.svg';
import PLKLogo from 'src/assets/logos/plk-wordmark.svg';
import styled from 'styled-components';
import { Title, Form, StyledButton, Container } from './styled';
import { Modal } from '../modal/Modal';
import { LanguageName, LocaleCode } from '../../generated/markets';

interface ILanguageSelectorProps {
  closeModal: () => void;
}

type FormValues = {
  language: LanguageOption | null;
};

type LanguageOption = {
  value: LocaleCode;
  label: LanguageName;
};

const defaultLanguage: LanguageOption = { value: 'en-US', label: 'English' };

export const LanguageSelector: FC<ILanguageSelectorProps> = ({ closeModal }) => {
  const brand = useBrand();
  const { setLocale, formatMessage } = useLocale();

  const initialValues: FormValues = {
    language: null,
  };

  const onSubmit = (values: FormValues) => {
    const locale = values.language!.value;
    setLocale(locale);
    closeModal();
  };

  const formik = useFormik<FormValues>({
    onSubmit,
    initialValues,
  });

  const logo = useMemo(
    () =>
      ({
        bk: BKLogo,
        th: THLogo,
        plk: PLKLogo,
      }[brand]),
    [brand],
  );

  return (
    <Modal isModalOpen onDismiss={closeModal}>
      <Container>
        <Logo src={logo} />
        <Title>{formatMessage({ id: 'config-locale.title' })}</Title>
        <FormikProvider value={formik}>
          <FormContent />
        </FormikProvider>
      </Container>
    </Modal>
  );
};

const Logo = withBrandTag(styled.img`
  margin: 0 auto;

  &[data-brand='bk'] {
    width: 130px;
  }

  &[data-brand='th'] {
    width: 210px;
  }

  &[data-brand='plk'] {
    width: 240px;
  }
`);

const FormContent: FC = ({ ...props }) => {
  const { handleSubmit, values } = useFormikContext<FormValues>();
  const { formatMessage, region } = useLocale();

  const supportedLanguages = useMemo(() => (region ? supportedLanguagesByRegion[region] : [defaultLanguage]), [region]);

  // We need to intercept the onChange handler due to react-select typings
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ onChange: onLanguageChange, name, value, onBlur }, , languageHelpers] = useField({ name: 'language' });

  const handleLanguageChange = (option: ValueType<OptionTypeBase>) => languageHelpers.setValue(option);

  return (
    <Form onSubmit={handleSubmit} {...props}>
      <Select
        id="select-language"
        name={name}
        label="Language"
        options={supportedLanguages}
        onChange={handleLanguageChange}
        onBlur={onBlur}
        value={value ?? ''}
      />
      <StyledButton disabled={!values.language} type="submit" kind="primary">
        {formatMessage({ id: 'config-locale.continue' })}
      </StyledButton>
    </Form>
  );
};
