import { Regions } from "../../../generated/markets";

export enum UserRole {
  Franchise = 'Franchise',
  Regular = 'Regular',
  Contractor = 'Contractor',
}

export enum Actions {
  UpdatePricing = 'updatePricing',
}

export const PERMISSIONS_FOR_ROLE = {
  [UserRole.Franchise]: [Actions.UpdatePricing],
  [UserRole.Regular]: [],
  [UserRole.Contractor]: [],
};

export interface IUserLoading {
  isLoading: true;
}

export interface IUserLoaded {
  isLoading: false;
  email: string;
  name: string;
  locale: string;
  sub: string;
  givenName: string;
  familyName: string;
  updatedAt: number;
  userId: string;
  userRole: UserRole;
  FranCountry: Regions;
}

export type IUser = IUserLoading | IUserLoaded;
