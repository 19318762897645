import { IGridSelections, ISelectRowPayload, ISelectAllPayload, IDeselectAllPayload } from './types/GridSelections';

export const gridSelections = {
  state: { itemGrid: new Set(), restaurantGrid: new Set() } as IGridSelections,
  reducers: {
    selectAll(state: IGridSelections, { gridType, rowIds }: ISelectAllPayload) {
      return { ...state, [gridType]: new Set([...rowIds]) };
    },
    deselectAll(state: IGridSelections, { gridType }: IDeselectAllPayload) {
      return { ...state, [gridType]: new Set() };
    },
    setGridSelection(state: IGridSelections, payload: ISelectRowPayload) {
      const { rowId, gridType, singleSelect } = payload;
      const selections = new Set([...state[gridType]]);

      // * If singleSelect enabled, treat this grid as single-select
      if (singleSelect && !selections.has(rowId)) {
        return {
          ...state,
          [gridType]: new Set([rowId]),
        };
      }

      if (selections.has(rowId)) {
        selections.delete(rowId);
      } else {
        selections.add(rowId);
      }
      return {
        ...state,
        [gridType]: selections,
      };
    },
  },
  selectors: {
    getState: () => (state: any) => state.gridSelections,
  },
};
