import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly _: Scalars['Boolean'];
  /** Update the price of one or more items at one or more stores */
  readonly updateItems: ReadonlyArray<RestaurantMenuItem>;
  /** Upload the prices for items at one or more stores */
  readonly uploadPrices: Scalars['String'];
  /** Update the availability of one or more items at one or more stores */
  readonly updateItemAvailability: ReadonlyArray<Restaurant>;
};


export type MutationUpdateItemsArgs = {
  items: ReadonlyArray<NewItemInput>;
  serviceMode?: Maybe<ServiceMode>;
};


export type MutationUploadPricesArgs = {
  storeId: Scalars['String'];
  items: ReadonlyArray<UploadPriceInput>;
};


export type MutationUpdateItemAvailabilityArgs = {
  items: ReadonlyArray<ItemAvailabilityInput>;
  serviceMode?: Maybe<ServiceMode>;
};

export type Query = {
  readonly __typename: 'Query';
  readonly _: Scalars['Boolean'];
  /** Get all restaurants. */
  readonly restaurants: ReadonlyArray<Restaurant>;
  /** Get all items in the menu. */
  readonly menuItems: ReadonlyArray<MenuItem>;
};


export type QueryRestaurantsArgs = {
  serviceMode?: Maybe<ServiceMode>;
};

export type NewItemInput = {
  readonly id: Scalars['String'];
  readonly price: Scalars['Int'];
};

export type UploadPriceInput = {
  readonly plu: Scalars['String'];
  readonly price: Scalars['Int'];
};

export type ItemAvailabilityInput = {
  /** A composite key in the format '{restaurant number}#{item id}'. */
  readonly id: Scalars['String'];
  /** The item's availability */
  readonly available: Scalars['Boolean'];
};

/** A combination of items. */
export type Combo = {
  readonly __typename: 'Combo';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** @deprecated Use '__typename' */
  readonly sanityType: Scalars['String'];
};


/** A product sold to the user */
export type Item = {
  readonly __typename: 'Item';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** @deprecated Use '__typename' */
  readonly sanityType: Scalars['String'];
};

/** Union of all MenuItems (distinguishable by the 'type' attribute) */
export type MenuItem = Modifier | ModifierMultiplier | Item | Offer | Combo;

/** A combination of item and price which corresponds to a given restaurant. */
export type RestaurantMenuItem = {
  readonly __typename: 'RestaurantMenuItem';
  /** A composite key in the format '{restaurant id}#{item id}'. */
  readonly id: Scalars['ID'];
  /** Unique identifier of an item. */
  readonly item: MenuItem;
  /** Price of an item for a given store. */
  readonly price: Scalars['Int'];
};

/** A modification to an item. */
export type Modifier = {
  readonly __typename: 'Modifier';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** @deprecated Use '__typename' */
  readonly sanityType: Scalars['String'];
};

/** A quantity of modifications to an item. */
export type ModifierMultiplier = {
  readonly __typename: 'ModifierMultiplier';
  readonly id: Scalars['ID'];
  /** Presentational string such as "Extra" for "Extra cheese". */
  readonly prefix: Scalars['String'];
  /** Quantity of modifiers. */
  readonly quantity: Scalars['Float'];
  /** Modifier of which this quantity applies to. */
  readonly modifier: Modifier;
  /** @deprecated Use '__typename' */
  readonly sanityType: Scalars['String'];
};

/** An offer for an item/combo. */
export type Offer = {
  readonly __typename: 'Offer';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** @deprecated Use '__typename' */
  readonly sanityType: Scalars['String'];
};

/**
 * PluField types.
 * deliveryPlus for delivery.
 * plus for the other service modes.
 */
export enum PluField {
  DELIVERYPLUS = 'deliveryPlus',
  PLUS = 'plus'
}

export type Restaurant = {
  readonly __typename: 'Restaurant';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly menuPrices: ReadonlyArray<RestaurantMenuItem>;
  readonly hasPlusData: Scalars['Boolean'];
  readonly group?: Maybe<RestaurantGroup>;
};


export type RestaurantMenuPricesArgs = {
  menuItemIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  serviceMode?: Maybe<ServiceMode>;
};

export type RestaurantGroup = {
  readonly __typename: 'RestaurantGroup';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly restaurants: ReadonlyArray<Restaurant>;
};


export type RestaurantGroupRestaurantsArgs = {
  serviceMode?: Maybe<ServiceMode>;
};

/**
 * All service modes.
 * Currently any non-DELIVERY service mode is treated as EAT_IN
 */
export enum ServiceMode {
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CATERING_PICKUP = 'CATERING_PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TAKEOUT = 'TAKEOUT'
}

/** Order status */
export enum OrderStatus {
  CATERING_DECLINED = 'CATERING_DECLINED',
  CATERING_ERROR = 'CATERING_ERROR',
  CATERING_PLACED = 'CATERING_PLACED',
  CATERING_SUCCESSFUL = 'CATERING_SUCCESSFUL',
  INSERT_ERROR = 'INSERT_ERROR',
  INSERT_REQUESTED = 'INSERT_REQUESTED',
  INSERT_SUCCESSFUL = 'INSERT_SUCCESSFUL',
  KOUNT_DENIAL = 'KOUNT_DENIAL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PRICE_ERROR = 'PRICE_ERROR',
  PRICE_REQUESTED = 'PRICE_REQUESTED',
  PRICE_SUCCESSFUL = 'PRICE_SUCCESSFUL',
  REFUND_ERROR = 'REFUND_ERROR',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_SUCCESSFUL = 'UPDATE_SUCCESSFUL',
  VALIDATION_ERROR = 'VALIDATION_ERROR'
}

/** Delivery status */
export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE'
}

/** Tablet status */
export enum TabletStatus {
  CASHIER_INPUT_FAILED = 'CASHIER_INPUT_FAILED',
  CASHIER_INPUT_REQUESTED = 'CASHIER_INPUT_REQUESTED',
  CASHIER_INPUT_SUCCESSFUL = 'CASHIER_INPUT_SUCCESSFUL'
}

export type ItemFragmentFragment = { readonly __typename: 'Item', readonly id: string, readonly name: string };

export type ComboFragmentFragment = { readonly __typename: 'Combo', readonly id: string, readonly name: string };

export type OfferFragmentFragment = { readonly __typename: 'Offer', readonly id: string, readonly name: string };

export type ModifierFragmentFragment = { readonly __typename: 'Modifier', readonly id: string, readonly name: string };

export type ModifierMultiplierFragmentFragment = { readonly __typename: 'ModifierMultiplier', readonly id: string, readonly prefix: string, readonly quantity: number, readonly modifier: (
    { readonly __typename: 'Modifier' }
    & ModifierFragmentFragment
  ) };

type MenuItemFragmentModifierFragment = (
  { readonly __typename: 'Modifier' }
  & ModifierFragmentFragment
);

type MenuItemFragmentModifierMultiplierFragment = (
  { readonly __typename: 'ModifierMultiplier' }
  & ModifierMultiplierFragmentFragment
);

type MenuItemFragmentItemFragment = (
  { readonly __typename: 'Item' }
  & ItemFragmentFragment
);

type MenuItemFragmentOfferFragment = (
  { readonly __typename: 'Offer' }
  & OfferFragmentFragment
);

type MenuItemFragmentComboFragment = (
  { readonly __typename: 'Combo' }
  & ComboFragmentFragment
);

export type MenuItemFragmentFragment = MenuItemFragmentModifierFragment | MenuItemFragmentModifierMultiplierFragment | MenuItemFragmentItemFragment | MenuItemFragmentOfferFragment | MenuItemFragmentComboFragment;

export type RestaurantMenuItemFragmentFragment = { readonly __typename: 'RestaurantMenuItem', readonly id: string, readonly price: number, readonly item: (
    { readonly __typename: 'Modifier' }
    & MenuItemFragmentModifierFragment
  ) | (
    { readonly __typename: 'ModifierMultiplier' }
    & MenuItemFragmentModifierMultiplierFragment
  ) | (
    { readonly __typename: 'Item' }
    & MenuItemFragmentItemFragment
  ) | (
    { readonly __typename: 'Offer' }
    & MenuItemFragmentOfferFragment
  ) | (
    { readonly __typename: 'Combo' }
    & MenuItemFragmentComboFragment
  ) };

export type RestaurantGroupFragmentFragment = { readonly __typename: 'RestaurantGroup', readonly id: string, readonly name?: Maybe<string> };

export type RestaurantFragmentFragment = { readonly __typename: 'Restaurant', readonly id: string, readonly name: string, readonly hasPlusData: boolean, readonly group?: Maybe<(
    { readonly __typename: 'RestaurantGroup' }
    & RestaurantGroupFragmentFragment
  )> };

export type UpdateItemsMutationVariables = Exact<{
  items: ReadonlyArray<NewItemInput> | NewItemInput;
  serviceMode?: Maybe<ServiceMode>;
}>;


export type UpdateItemsMutation = { readonly __typename: 'Mutation', readonly updateItems: ReadonlyArray<(
    { readonly __typename: 'RestaurantMenuItem' }
    & RestaurantMenuItemFragmentFragment
  )> };

export type UploadPricesMutationVariables = Exact<{
  storeId: Scalars['String'];
  items: ReadonlyArray<UploadPriceInput> | UploadPriceInput;
}>;


export type UploadPricesMutation = { readonly __typename: 'Mutation', readonly uploadPrices: string };

export type UpdateItemAvailabilityMutationVariables = Exact<{
  items: ReadonlyArray<ItemAvailabilityInput> | ItemAvailabilityInput;
  itemIdsToRefetch?: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  serviceMode?: Maybe<ServiceMode>;
}>;


export type UpdateItemAvailabilityMutation = { readonly __typename: 'Mutation', readonly updateItemAvailability: ReadonlyArray<(
    { readonly __typename: 'Restaurant', readonly menuPrices: ReadonlyArray<(
      { readonly __typename: 'RestaurantMenuItem' }
      & RestaurantMenuItemFragmentFragment
    )> }
    & RestaurantFragmentFragment
  )> };

export type GetItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetItemsQuery = { readonly __typename: 'Query', readonly menuItems: ReadonlyArray<(
    { readonly __typename: 'Modifier' }
    & MenuItemFragmentModifierFragment
  ) | (
    { readonly __typename: 'ModifierMultiplier' }
    & MenuItemFragmentModifierMultiplierFragment
  ) | (
    { readonly __typename: 'Item' }
    & MenuItemFragmentItemFragment
  ) | (
    { readonly __typename: 'Offer' }
    & MenuItemFragmentOfferFragment
  ) | (
    { readonly __typename: 'Combo' }
    & MenuItemFragmentComboFragment
  )> };

export type GetRestaurantsQueryVariables = Exact<{
  menuItemIds?: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  serviceMode?: Maybe<ServiceMode>;
}>;


export type GetRestaurantsQuery = { readonly __typename: 'Query', readonly restaurants: ReadonlyArray<(
    { readonly __typename: 'Restaurant', readonly menuPrices: ReadonlyArray<(
      { readonly __typename: 'RestaurantMenuItem' }
      & RestaurantMenuItemFragmentFragment
    )> }
    & RestaurantFragmentFragment
  )> };

export const ItemFragmentFragmentDoc = gql`
    fragment ItemFragment on Item {
  id
  name
}
    `;
export const ModifierFragmentFragmentDoc = gql`
    fragment ModifierFragment on Modifier {
  id
  name
}
    `;
export const ModifierMultiplierFragmentFragmentDoc = gql`
    fragment ModifierMultiplierFragment on ModifierMultiplier {
  id
  prefix
  quantity
  modifier {
    ...ModifierFragment
  }
}
    ${ModifierFragmentFragmentDoc}`;
export const ComboFragmentFragmentDoc = gql`
    fragment ComboFragment on Combo {
  id
  name
}
    `;
export const OfferFragmentFragmentDoc = gql`
    fragment OfferFragment on Offer {
  id
  name
}
    `;
export const MenuItemFragmentFragmentDoc = gql`
    fragment MenuItemFragment on MenuItem {
  ...ItemFragment
  ...ModifierFragment
  ...ModifierMultiplierFragment
  ...ComboFragment
  ...OfferFragment
}
    ${ItemFragmentFragmentDoc}
${ModifierFragmentFragmentDoc}
${ModifierMultiplierFragmentFragmentDoc}
${ComboFragmentFragmentDoc}
${OfferFragmentFragmentDoc}`;
export const RestaurantMenuItemFragmentFragmentDoc = gql`
    fragment RestaurantMenuItemFragment on RestaurantMenuItem {
  id
  price
  item {
    ...MenuItemFragment
  }
}
    ${MenuItemFragmentFragmentDoc}`;
export const RestaurantGroupFragmentFragmentDoc = gql`
    fragment RestaurantGroupFragment on RestaurantGroup {
  id
  name
}
    `;
export const RestaurantFragmentFragmentDoc = gql`
    fragment RestaurantFragment on Restaurant {
  id
  name
  hasPlusData
  group {
    ...RestaurantGroupFragment
  }
}
    ${RestaurantGroupFragmentFragmentDoc}`;
export const UpdateItemsDocument = gql`
    mutation UpdateItems($items: [NewItemInput!]!, $serviceMode: ServiceMode) {
  updateItems(items: $items, serviceMode: $serviceMode) {
    ...RestaurantMenuItemFragment
  }
}
    ${RestaurantMenuItemFragmentFragmentDoc}`;
export type UpdateItemsMutationFn = ApolloReactCommon.MutationFunction<UpdateItemsMutation, UpdateItemsMutationVariables>;

/**
 * __useUpdateItemsMutation__
 *
 * To run a mutation, you first call `useUpdateItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemsMutation, { data, loading, error }] = useUpdateItemsMutation({
 *   variables: {
 *      items: // value for 'items'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useUpdateItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemsMutation, UpdateItemsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemsMutation, UpdateItemsMutationVariables>(UpdateItemsDocument, baseOptions);
      }
export type UpdateItemsMutationHookResult = ReturnType<typeof useUpdateItemsMutation>;
export type UpdateItemsMutationResult = ApolloReactCommon.MutationResult<UpdateItemsMutation>;
export type UpdateItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemsMutation, UpdateItemsMutationVariables>;
export const UploadPricesDocument = gql`
    mutation UploadPrices($storeId: String!, $items: [UploadPriceInput!]!) {
  uploadPrices(storeId: $storeId, items: $items)
}
    `;
export type UploadPricesMutationFn = ApolloReactCommon.MutationFunction<UploadPricesMutation, UploadPricesMutationVariables>;

/**
 * __useUploadPricesMutation__
 *
 * To run a mutation, you first call `useUploadPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPricesMutation, { data, loading, error }] = useUploadPricesMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUploadPricesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPricesMutation, UploadPricesMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPricesMutation, UploadPricesMutationVariables>(UploadPricesDocument, baseOptions);
      }
export type UploadPricesMutationHookResult = ReturnType<typeof useUploadPricesMutation>;
export type UploadPricesMutationResult = ApolloReactCommon.MutationResult<UploadPricesMutation>;
export type UploadPricesMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPricesMutation, UploadPricesMutationVariables>;
export const UpdateItemAvailabilityDocument = gql`
    mutation UpdateItemAvailability($items: [ItemAvailabilityInput!]!, $itemIdsToRefetch: [String!], $serviceMode: ServiceMode) {
  updateItemAvailability(items: $items, serviceMode: $serviceMode) {
    ...RestaurantFragment
    menuPrices(menuItemIds: $itemIdsToRefetch, serviceMode: $serviceMode) {
      ...RestaurantMenuItemFragment
    }
  }
}
    ${RestaurantFragmentFragmentDoc}
${RestaurantMenuItemFragmentFragmentDoc}`;
export type UpdateItemAvailabilityMutationFn = ApolloReactCommon.MutationFunction<UpdateItemAvailabilityMutation, UpdateItemAvailabilityMutationVariables>;

/**
 * __useUpdateItemAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateItemAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemAvailabilityMutation, { data, loading, error }] = useUpdateItemAvailabilityMutation({
 *   variables: {
 *      items: // value for 'items'
 *      itemIdsToRefetch: // value for 'itemIdsToRefetch'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useUpdateItemAvailabilityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemAvailabilityMutation, UpdateItemAvailabilityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemAvailabilityMutation, UpdateItemAvailabilityMutationVariables>(UpdateItemAvailabilityDocument, baseOptions);
      }
export type UpdateItemAvailabilityMutationHookResult = ReturnType<typeof useUpdateItemAvailabilityMutation>;
export type UpdateItemAvailabilityMutationResult = ApolloReactCommon.MutationResult<UpdateItemAvailabilityMutation>;
export type UpdateItemAvailabilityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemAvailabilityMutation, UpdateItemAvailabilityMutationVariables>;
export const GetItemsDocument = gql`
    query GetItems {
  menuItems {
    ...MenuItemFragment
  }
}
    ${MenuItemFragmentFragmentDoc}`;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, baseOptions);
      }
export function useGetItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, baseOptions);
        }
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsQueryResult = ApolloReactCommon.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
export const GetRestaurantsDocument = gql`
    query GetRestaurants($menuItemIds: [String!], $serviceMode: ServiceMode) {
  restaurants(serviceMode: $serviceMode) {
    ...RestaurantFragment
    menuPrices(menuItemIds: $menuItemIds, serviceMode: $serviceMode) {
      ...RestaurantMenuItemFragment
    }
  }
}
    ${RestaurantFragmentFragmentDoc}
${RestaurantMenuItemFragmentFragmentDoc}`;

/**
 * __useGetRestaurantsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsQuery({
 *   variables: {
 *      menuItemIds: // value for 'menuItemIds'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useGetRestaurantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRestaurantsQuery, GetRestaurantsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRestaurantsQuery, GetRestaurantsQueryVariables>(GetRestaurantsDocument, baseOptions);
      }
export function useGetRestaurantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRestaurantsQuery, GetRestaurantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRestaurantsQuery, GetRestaurantsQueryVariables>(GetRestaurantsDocument, baseOptions);
        }
export type GetRestaurantsQueryHookResult = ReturnType<typeof useGetRestaurantsQuery>;
export type GetRestaurantsLazyQueryHookResult = ReturnType<typeof useGetRestaurantsLazyQuery>;
export type GetRestaurantsQueryResult = ApolloReactCommon.QueryResult<GetRestaurantsQuery, GetRestaurantsQueryVariables>;