import { IRestaurantData } from 'src/components/grid/types';

export interface IRestaurantGrid {
  data: IRestaurantData[];
  filters: { [key: string]: { [key: string]: boolean } };
  searchTerm: string;
}

const initialState: IRestaurantGrid = {
  data: [],
  filters: {
    // in case we want to add some filters later
  },
  searchTerm: '',
};

export const restaurantGrid = {
  state: initialState,

  reducers: {
    setFilterState(
      state: IRestaurantGrid,
      { headerName, chosenFilters }: { headerName: string; chosenFilters: { [key: string]: boolean } },
    ) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [headerName]: {
            ...chosenFilters,
          },
        },
      };
    },
    setSearchTerm(state: IRestaurantGrid, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
  },

  selectors: {
    getState: () => (state: any) => state.restaurantGrid,
  },
};
