import React, { lazy, Suspense, FC, useMemo, ReactNode } from 'react';
import { ApolloProvider as ApolloClientProvider } from '@apollo/react-hooks';
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import introspectionQueryResultData from 'src/graphql/__generated__/introspectionResult';

import { store } from './rematch/store';
import { LocaleProvider, useLocale } from './locale';
import Shell, { ThemeProvider } from './pages/shell';
import { AuthProvider, useAuthContext } from './Auth';

const PricingPage = lazy(() => import('./pages/pricing-availability'));
const DiagnosticsPage = lazy(() => import('./pages/diagnostics'));
const OrdersPage = lazy(() => import('./pages/orders'));
const UploadPricesPage = lazy(() => import('./pages/upload-prices'));
const UploadItemDataPage = lazy(() => import('./pages/upload-item-data'));

/** Apollo provider conditional on region context */
const ApolloProvider: FC<{ children: ReactNode }> = (props) => {
  const { region, locale } = useLocale();
  const { authClient } = useAuthContext();

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        cache: new InMemoryCache({
          fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
          }),
        }),
        request: (operation) => {
          operation.setContext({
            headers: {
              'x-ui-region': region,
              'x-ui-locale': locale,
              'okta-id-token': authClient.getIdToken(),
            },
          });
        },
      }),
    [region, locale, authClient],
  );

  return <ApolloClientProvider {...props} client={client} />;
};

/* eslint-disable react/jsx-props-no-spreading */
const AppRoutes: FC = () => (
  <Switch>
    <AuthProvider>
      <LocaleProvider>
        <Route path="/">
          <ReduxProvider store={store}>
            <ApolloProvider>
              <Shell>
                <Suspense fallback={null}>
                  <Switch>
                    <Route path="/pricing-availability" component={PricingPage} />
                    <Route path="/diagnostics" component={DiagnosticsPage} />
                    <Route path="/orders" component={OrdersPage} />
                    <Route path="/upload-prices" component={UploadPricesPage} />
                    <Route path="/upload-item-data" component={UploadItemDataPage} />
                    <Redirect to="/pricing-availability" />
                  </Switch>
                </Suspense>
              </Shell>
            </ApolloProvider>
          </ReduxProvider>
        </Route>
      </LocaleProvider>
    </AuthProvider>
  </Switch>
);

const App: FC = () => (
  <>
    <ThemeProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  </>
);

export default App;
