export interface ILaunchDarkly {
  isLDReady: boolean;
  enableDiagnostics: boolean;
  enablePricingAdjustments: boolean;
  enableUploadPricesSection: boolean;
  enableUploadItemDataSection: boolean;
}

export const launchDarkly = {
  state: {} as ILaunchDarkly,
};
