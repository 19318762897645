import styled from 'styled-components';

export const Card = styled.section`
  flex: 1 0;
  margin: 1rem;
  padding: 18px 24px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h3`
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
`;
