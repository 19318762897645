import { RematchRootState, RematchDispatch } from '@rematch/core';
import LogRocket from 'logrocket';
import { IUser, PERMISSIONS_FOR_ROLE, Actions } from './types/User';
import { RootModel } from '.';

// eslint-disable-next-line no-shadow
export const canUserPerformAction = (user: IUser, action: Actions) => {
  if (!user.isLoading) {
    const permissions: Actions[] = PERMISSIONS_FOR_ROLE[user.userRole] ?? [];
    return permissions.includes(action);
  }
  return false;
};

export const user = {
  state: {
    isLoading: true,
  } as IUser,
  reducers: {
    setUser(state: IUser, payload: any) {
      const role = payload.UserType;
      return {
        ...state,
        isLoading: false,
        email: payload.email,
        locale: payload.locale,
        name: payload.name,
        givenName: payload.given_name,
        familyName: payload.family_name,
        sub: payload.sub,
        updatedAt: payload.updated_at,
        userId: payload.UserID,
        userRole: role,
      };
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async setUserAsync(payload: any, rootState: RematchRootState<RootModel>) {
      if (!payload) {
        return;
      }

      // @ts-ignore
      await window.ldClient?.identify({
        email: payload.email,
        key: payload.sub,
      });

      await LogRocket.identify(payload.UserID);

      dispatch.user.setUser(payload, rootState.user);
    },
  }),
};
