import { ServiceMode } from "src/graphql";

export const serviceMode = {
  state: ServiceMode.EAT_IN,
  reducers: {
    setServieMode(_: ServiceMode, newServiceMode: ServiceMode) {
      return newServiceMode;
    },
  },
  selectors: {
    getState: () => (state: any) => state.serviceMode,
  },
};
