import styled from 'styled-components';
import { Button, PageHeader } from 'src/components';

export const Page = styled.div`
  background: ${({ theme }) => theme.color.light['-2']};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.scale(2)};
  background: ${({ theme }) => theme.color.light['0']};
  border-radius: 20px;
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 100px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const Title = styled(PageHeader)`
  text-align: center;
  font-size: 24px;
  margin: ${(p) => p.theme.scale(1)} 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: ${(p) => p.theme.scale(1.5)};
  }
`;
