import React, { FC, ComponentProps, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { useRematch } from 'src/rematch';
import { useLocale } from 'src/locale';
import { LogOutContainer, Nav, NavItem, NavSubheading, NavTitle, NavTitleText, NavTitleLogo } from 'src/components/Nav';
import { useBrand } from 'src/brand';
import THLogo from 'src/assets/logos/th-wordmark-wrap.svg';
import BKLogo from 'src/assets/logos/bk-logo.svg';
import PLKLogo from 'src/assets/logos/plk-logo.svg';
import { useAuthContext } from 'src/Auth';
import LanguageSelector from 'src/components/language-selector';

const Container = styled.div`
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Page = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 39px 42px;
  overflow-y: auto;

  background: #f6f6f6;
`;

const ChangeLanguage = styled(NavItem).attrs(() => ({
  // TODO?: button may make more semantic sense
  as: 'a',
  to: '',
}))`
  background-color: transparent;
  cursor: pointer;
`;

export const Shell: FC<ComponentProps<typeof Page>> = ({ children, ...props }) => {
  const brand = useBrand();
  const { formatMessage } = useLocale();
  const { authClient } = useAuthContext();
  const {
    enableDiagnostics,
    enableUploadPricesSection,
    enableUploadItemDataSection,
  } = useRematch(({ launchDarkly }) => ({
    enableDiagnostics: launchDarkly.enableDiagnostics,
    enableUploadPricesSection: launchDarkly.enableUploadPricesSection,
    enableUploadItemDataSection: launchDarkly.enableUploadItemDataSection,
  }));
  const logo = useMemo(() => ({ bk: BKLogo, th: THLogo, plk: PLKLogo }[brand]), [brand]);
  const [languageSelectorOpen, setlanguageSelectorOpen] = useState(false);

  const logout = useCallback(() => {
    // TODO! Find out what's wrong with our access when trying to revoke access token
    // TODO! There's a possibility that it could be to do with the IP not being on the
    // TODO! allowed list, though it seems unlikely as token retrieval works.
    // TODO! https://devforum.okta.com/t/okta-react-logout-not-working/11422/4
    // authClient.revokeAccessToken(authState.accessToken);

    authClient
      .signOut({
        revokeAccessToken: false,
      })
      .then(() => localStorage.clear());
  }, [authClient]);

  const navHeading = {
    bk: 'shell.nav.heading.bk',
    plk: 'shell.nav.heading.plk',
    th: 'shell.nav.heading.th',
  } as const;

  const toggleLanguageSelectorOpen = useCallback(() => setlanguageSelectorOpen((prev) => !prev), []);

  return (
    <Container {...props}>
      <ToastContainer />
      <Nav>
        <NavTitle>
          <NavTitleLogo src={logo} />
          <NavTitleText>{formatMessage({ id: navHeading[brand] })}</NavTitleText>
        </NavTitle>
        <NavSubheading>{formatMessage({ id: 'shell.menu' })}</NavSubheading>
        <ul>
          <>
            <li>
              <NavItem exact to="/pricing-availability">
                {formatMessage({ id: 'shell.pricingAvailLinkText' })}
              </NavItem>
            </li>
            <li>
              <NavItem exact to="/orders">
                {formatMessage({ id: 'shell.ordersText' })}
              </NavItem>
            </li>
            {enableUploadPricesSection && (
              <li>
                <NavItem exact to="/upload-prices">
                  {formatMessage({ id: 'upload-prices.uploadText' })}
                </NavItem>
              </li>
            )}
            {enableUploadItemDataSection && (
              <li>
                <NavItem exact to="/upload-item-data">
                  {formatMessage({ id: 'upload-item-data.uploadText' })}
                </NavItem>
              </li>
            )}
            {enableDiagnostics && (
              <li>
                <NavItem exact to="/diagnostics">
                  {formatMessage({ id: 'shell.diagnosticsLinkText' })}
                </NavItem>
              </li>
            )}
          </>
        </ul>
        <LogOutContainer>
          <ChangeLanguage onClick={toggleLanguageSelectorOpen}>
            {formatMessage({ id: 'shell.changeLanguage' })}
          </ChangeLanguage>
          <NavItem to="/login" onClick={logout}>
            {formatMessage({ id: 'shell.logOut' })}
          </NavItem>
        </LogOutContainer>
      </Nav>
      <Page>{children}</Page>
      {languageSelectorOpen ? <LanguageSelector closeModal={toggleLanguageSelectorOpen} /> : null}
    </Container>
  );
};
