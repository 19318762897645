import React from 'react';
import ReactDOM from 'react-dom';

import { init as initDataDogLogs } from 'src/utils/datadog';
import { init as initLogRocket } from 'src/utils/logrocket';
import App from './App';

initDataDogLogs();
initLogRocket();

ReactDOM.render(<App />, document.getElementById('root'));
