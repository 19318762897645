
      export type IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "MenuItem",
        "possibleTypes": [
          {
            "name": "Modifier"
          },
          {
            "name": "ModifierMultiplier"
          },
          {
            "name": "Item"
          },
          {
            "name": "Offer"
          },
          {
            "name": "Combo"
          }
        ]
      }
    ]
  }
};
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "MenuItem",
        "possibleTypes": [
          {
            "name": "Modifier"
          },
          {
            "name": "ModifierMultiplier"
          },
          {
            "name": "Item"
          },
          {
            "name": "Offer"
          },
          {
            "name": "Combo"
          }
        ]
      }
    ]
  }
};
      export default result;
    