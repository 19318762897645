import { user } from './user';
import { launchDarkly } from './launchDarkly';
import { gridSelections } from './gridSelections';
import { itemGrid } from './itemsGrid';
import { orderGrid } from './ordersGrid';
import { restaurantGrid } from './restaurantsGrid';
import { serviceMode } from './serviceMode';
import { data } from './data';

export interface RootModel {
  data: typeof data;
  user: typeof user;
  itemGrid: typeof itemGrid;
  orderGrid: typeof orderGrid;
  restaurantGrid: typeof restaurantGrid;
  gridSelections: typeof gridSelections;
  launchDarkly: typeof launchDarkly;
  serviceMode: typeof serviceMode;
}

export const models: RootModel = {
  data,
  user,
  itemGrid,
  orderGrid,
  restaurantGrid,
  launchDarkly,
  gridSelections,
  serviceMode,
};
