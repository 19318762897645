import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import DateView from 'react-datepicker';
import { useField } from 'formik';
import { ErrorStyled } from './styled/ErrorStyled';
import { LabelStyled } from './styled/LabelStyled';

import 'react-datepicker/dist/react-datepicker.css';

interface IDatePicker {
  label?: string;
  maxDateOfToday?: boolean;
  id: string;
  dateFormat: string;
}

const DatePickerStyles = styled.div`
  height: fit-content;
  margin-top: 2.5rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
  }
`;

const DateViewStyled = styled(DateView)`
  width: 19rem;
  height: 3rem;
`;

export const DatePicker: FC<IDatePicker> = ({ label, id, maxDateOfToday, ...props }) => {
  const [datePickerField, datePickerMeta, datePickerHelper] = useField(id);
  const [today] = useState(new Date());

  const onChange = useCallback((newDate) => {
    datePickerHelper.setTouched(true, false);
    datePickerHelper.setValue(newDate);
  }, [datePickerHelper]);

  return (
    <DatePickerStyles>
      <LabelStyled>{label}</LabelStyled>
      <DateViewStyled
        id={id}
        selected={datePickerField.value}
        {...props}
        {...datePickerField}
        maxDate={maxDateOfToday ? today : null}
        onChange={onChange}
        isClearable
      />
      {datePickerMeta.touched && datePickerMeta.error && <ErrorStyled>{datePickerMeta.error}</ErrorStyled>}
    </DatePickerStyles>
  );
};
