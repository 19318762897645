import React, { ForwardRefRenderFunction, createContext, FC, forwardRef, useContext } from 'react';

export type Brand = typeof process.env.REACT_APP_BRAND;

export const BrandContext = createContext(process.env.REACT_APP_BRAND);

/* Get current brand. */
export const useBrand = () => useContext(BrandContext);

/*
 * Adds `data-brand` tag for brand specific edge cases.
 *
 * Example:
 * const Button = withBrandTag(styled.button`
 *   &[data-brand="bk"] {
 *     // brand specific style
 *   }
 * `)
 */
export const withBrandTag = <T extends FC | ForwardRefRenderFunction<any, any>>(Component: T) => {
  const Wrapper = forwardRef((props, ref) => {
    const brand = useBrand();

    // @ts-ignore
    return <Component ref={ref} {...props} data-brand={brand} />;
  });
  Wrapper.displayName = `withBrandTag(${Component.displayName})`;
  return (Wrapper as any) as T;
};
