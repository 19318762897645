import React, { createContext, FC, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createIntl, IntlShape, createIntlCache, MessageDescriptor } from 'react-intl';
import { datadogLogs } from '@datadog/browser-logs';

import enDictionary from 'src/locale/dictionaries/en.json';
import { useAuthContext } from 'src/Auth';
import { LocalizedDictionary } from './types';
import { Currency, LocaleCode, LanguageName, MARKETS, Regions } from '../generated/markets';

export * from './types';

const LOCALE_SEPARATOR = '-';

const CURRENCY_MAPPING: Record<string, Currency> = Object.fromEntries(
  MARKETS.map(market => [market.regionCode, market.regionCurrency]),
);

// * The first language in the supported set will be treated as the default language for that region
export const supportedLanguagesByRegion: {
  [
  key: string]: {
    value: LocaleCode;
    label: LanguageName
  }[]
} = Object.fromEntries(
  MARKETS.map(market => [
    market.regionCode,
    market.regionLanguages.map(
      language => ({ value: language.languageLocale, label: language.languageName }),
    ),
  ],
  ),
);

interface ILocaleContext extends Omit<IntlShape, 'formatMessage'> {
  /** Language and grammar (user configurable). */
  locale: LocaleCode;
  /** Change language/grammar. */
  setLocale: (args: LocaleCode) => void;
  /** Physical region where user is located. */
  region?: Regions;
  /** Derived state based on region. */
  currency?: Currency;
  /** Type safe message retrieval */
  formatMessage: PatchFunction<IntlShape['formatMessage']>;
}

type LocaleProviderProps = {
  /** Force region (for storybook/testing only!). */
  forceRegion?: Regions;
};

export const LocaleContext = createContext<ILocaleContext>({} as ILocaleContext);

export const useLocale = () => useContext(LocaleContext);

const getDefaultLanguageForRegion = (region: Regions) => supportedLanguagesByRegion[region][0].value;

const getLocaleDictionaries = async (locale: LocaleCode) => {
  const [baseLanguage] = locale?.split(LOCALE_SEPARATOR);

  return Promise.all([
    import(`./dictionaries/${baseLanguage}.json`),
    import(`./dictionaries/${locale?.replace('-', '_')}.json`).catch((err) => {
      datadogLogs.logger.error(`Error loading translations: ${err}`);
      return {};
    }),
  ]);
};

export const LocaleProvider: FC<LocaleProviderProps> = ({ children, forceRegion }) => {
  const cache = useRef(createIntlCache());
  const { authClient } = useAuthContext();

  // TODO? Refactor to use public APIs (e.g. getUserInfo)
  let userRegion = authClient.authStateManager._authState.idToken?.claims.FranCountry as Regions; // eslint-disable-line no-underscore-dangle
  const regionOverride = new URLSearchParams(window.location.search).get('regionOverride');
  // * Allow a custom region override ONLY on dev
  if (process.env.REACT_APP_STAGE === 'dev' && regionOverride) {
    const isValidRegion = regionOverride && (Object.values(Regions) as string[]).includes(regionOverride);

    if (isValidRegion) {
      userRegion = regionOverride as Regions;
    }
  }

  const region = forceRegion || userRegion;
  const currency = useMemo(() => (region ? CURRENCY_MAPPING[region] : undefined) as ILocaleContext['currency'], [
    region,
  ]);

  const hasSetLocale = useRef(true);
  const [{ messages, locale }, setMessagesAndLocale] = useState<{
    messages: Record<string, string>;
    locale: LocaleCode
  }>({
    messages: enDictionary,
    locale: getDefaultLanguageForRegion(region),
  });

  // Set locale and messages at the same time
  const setLocale = useCallback(async (newLocale: LocaleCode) => {
    try {
      const [baseDictionary, localeDictionary] = await getLocaleDictionaries(newLocale);
      hasSetLocale.current = false;
      setMessagesAndLocale({
        locale: newLocale,
        messages: {
          ...enDictionary,
          ...baseDictionary,
          ...localeDictionary,
        },
      });
    } catch (err) {
      setMessagesAndLocale({
        locale: 'en-US',
        messages: {
          ...enDictionary,
        },
      });
    }
  }, []);

  // Load non-default locale on first render
  useLayoutEffect(() => {
    const localeFromStorage = (localStorage.getItem('selectedLocale') as LocaleCode) || null;

    if (localeFromStorage === null) {
      return;
    }

    setLocale(localeFromStorage);
  }, [setLocale]);

  useLayoutEffect(() => {
    // Ignore default locale (not user preference)
    if (hasSetLocale.current) {
      return;
    }

    window.localStorage.setItem('selectedLocale', locale);
  }, [locale]);

  useLayoutEffect(() => {
    if (!region) {
      return;
    }

    datadogLogs.addLoggerGlobalContext('region', region);
  }, [region]);

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale,
          messages,
        },
        cache.current,
      ),
    [locale, messages],
  );

  const state = useMemo(
    () => ({
      ...intl,
      locale,
      setLocale,
      region,
      currency,
    }),
    [intl, locale, setLocale, region, currency],
  );

  return <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>;
};

/** Override formatMessage functions. */
type PatchFunction<F> = F extends (descriptor: MessageDescriptor, ...args: infer P) => infer R
  ? (descriptor: { id: keyof LocalizedDictionary }, ...args: P) => string
  : F;
