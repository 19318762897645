import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withBrandTag } from 'src/brand';

export const NavTitle = withBrandTag(styled.div`
  height: 90px;
  background: ${({ theme }) => theme.color.primary['-6']};
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &[data-brand='th'] {
    background: ${({ theme }) => theme.color.light['0']};
  }
`);

export const NavTitleLogo = styled.img`
  margin: 0 15px;
  width: 60px;
`;

export const NavTitleText = styled.h1`
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-shadow: 2px 0px 0px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 0;
  flex-basis: 317px;
  width: 317px;
  height: 100%;
  background: ${({ theme }) => theme.color.primary['0']};
  border-radius: 0px;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
    }
  }
`;

export const NavSubheading = styled.h3`
  width: 183px;
  height: 28px;

  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-transform: uppercase;
  margin-left: 37px;
  margin-top: 14px;
  margin-bottom: 7px;
`;

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 72px;
  padding-left: 38px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  line-height: 28px;
  text-decoration: none;

  &.active {
    background-color: ${({ theme }) => theme.color.primary['-4']};
  }
`;

export const LogOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;
