import React, { FC, ReactNode } from 'react';
import { mix, modularScale } from 'polished';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider, DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet';

// COLORS
const primary = '#c8102e';
const dark = '#1c0b00';
const light = '#fff';
const green = '#31bd3d';
const red = '#ff2155';

const scaleRoot = 18;

const theme: DefaultTheme = {
  scale: (...args: Parameters<typeof modularScale>) =>
    modularScale(args[0], args[1] || `${scaleRoot}px`, args[2] || 1.5),
  color: {
    primary: {
      '-9': mix(0.1, primary, dark),
      '-8': mix(0.2, primary, dark),
      '-7': mix(0.3, primary, dark),
      '-6': mix(0.4, primary, dark),
      '-5': mix(0.5, primary, dark),
      '-4': mix(0.6, primary, dark),
      '-3': mix(0.7, primary, dark),
      '-2': mix(0.8, primary, dark),
      '-1': mix(0.9, primary, dark),
      '0': primary,
      '+1': mix(0.9, primary, light),
      '+2': mix(0.8, primary, light),
      '+3': mix(0.7, primary, light),
      '+4': mix(0.6, primary, light),
      '+5': mix(0.5, primary, light),
      '+6': mix(0.4, primary, light),
      '+7': mix(0.3, primary, light),
      '+8': mix(0.2, primary, light),
      '+9': mix(0.1, primary, light),
    },
    dark: {
      '0': dark,
      '+1': mix(0.06, light, dark),
      '+2': mix(0.12, light, dark),
      '+3': mix(0.18, light, dark),
      '+4': mix(0.24, light, dark),
      '+5': mix(0.3, light, dark),
      '+6': mix(0.36, light, dark),
      '+7': mix(0.42, light, dark),
    },
    medium: {
      '0': mix(0.21, dark, light),
      '-1': mix(0.25, dark, light),
      '-2': mix(0.29, dark, light),
      '-3': mix(0.33, dark, light),
      '-4': mix(0.37, dark, light),
      '-5': mix(0.41, dark, light),
      '-6': mix(0.45, dark, light),
      '-7': mix(0.49, dark, light),
    },
    light: {
      '0': light,
      '-1': mix(0.03, dark, light),
      '-2': mix(0.06, dark, light),
      '-3': mix(0.09, dark, light),
      '-4': mix(0.12, dark, light),
      '-5': mix(0.15, dark, light),
      '-6': mix(0.18, dark, light),
      '-7': mix(0.21, dark, light),
    },
    red: {
      '-4': mix(0.6, red, dark),
      '-3': mix(0.7, red, dark),
      '-2': mix(0.8, red, dark),
      '-1': mix(0.9, red, dark),
      '0': red,
      '+1': mix(0.9, red, light),
      '+2': mix(0.8, red, light),
      '+3': mix(0.7, red, light),
      '+4': mix(0.6, red, light),
    },
    green: {
      '0': green,
    },
  },
};

const GlobalStyle = createGlobalStyle`
  html {
    font-family: "sofia_pro", sans-serif;
  }

  h1, h2, h3 {
    font-family: "sofia_pro", sans-serif;
  }
`;

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <Helmet>
      <link rel="stylesheet" type="text/css" href={`${process.env.PUBLIC_URL}/th/fonts.css`} />
    </Helmet>
    <StyledThemeProvider theme={theme}>
      {children}
      <GlobalStyle />
    </StyledThemeProvider>
  </>
);

export default ThemeProvider;
