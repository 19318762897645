import React, { FC, useMemo } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { useTheme } from 'styled-components';

export const LoadingAnimation: FC = () => {
  const theme = useTheme();
  const fillColor = useMemo(() => theme.color.primary[0], [theme]);

  if (process.env.NODE_ENV === 'development') {
    return <PacmanLoader size={25} loading color={fillColor} />;
  }

  return <PulseLoader size={25} loading color={fillColor} />;
};
