import React, { createContext, useMemo, useEffect, useContext, FC, useState } from 'react';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';

import Loading from './components/Loading';
import { dispatch } from './rematch/store';

export const OKTA_CONFIG = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false,
  tokenManager: {
    autoRenew: true,
  },
};

type AuthContext = {
  authClient: OktaAuth;
};

export const AuthContext = createContext<AuthContext>(null as any);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ isAuthenticated: false, isPending: true });

  const authClient = useMemo(() => new OktaAuth(OKTA_CONFIG), []);

  useEffect(() => {
    // * Subscribe with internal state is needed to trigger updates/redirect
    authClient.authStateManager.subscribe(setAuthState);

    // Update for initial render
    authClient.authStateManager.updateAuthState();

    // Check for changes to local storage every second
    const interval = window.setInterval(() => authClient.authStateManager.updateAuthState(), 1000);
    return () => clearInterval(interval);
  }, [authClient]);

  // Handle token retrieval
  useEffect(() => {
    const { isPending, isAuthenticated } = authState;
    // Ignore if pending or authenticated
    if (isPending || isAuthenticated) {
      return;
    }

    // Store tokens if sourced from redirect
    if (authClient.token.isLoginRedirect()) {
      authClient.storeTokensFromRedirect();
      return;
    }

    authClient.token.getWithRedirect();
  }, [authClient, authState]);

  // Handle user object
  useEffect(() => {
    const { isPending, isAuthenticated, idToken } = authState;
    if (isPending || !isAuthenticated || !idToken?.claims) {
      return;
    }

    // TODO? Retrieve user info from Auth context as opposed to Redux
    dispatch.user.setUserAsync(idToken.claims);
  }, [authClient, authState]);

  // Pending auth check / redirecting to login / fetching user
  if (authState.isPending || !authState.isAuthenticated) {
    return <Loading data-testid="loading" />;
  }

  if (!authState.idToken?.claims) {
    // TODO! Consider i18n now that LocaleProvider is a descendent of AuthProvider
    return (
      <>
        Error fetching user
        <a href="/">Reload</a>
      </>
    );
  }

  return <AuthContext.Provider value={{ authClient }}>{children}</AuthContext.Provider>;
};
