export interface IItemGrid {
  data: IItemGridRow[];
  filters: { [key: string]: { [key: string]: boolean } };
  searchTerm: string;
}

interface IItemGridRow {
  type: string;
  section: string | null;
  name: string;
  price: number;
}

const initialState: IItemGrid = {
  data: [],
  filters: {
    // Type
    type: { item: false, offer: false, combo: false },
    // Section
    section: {
      chicken: false,
      burger: false,
      side: false,
      drink: false,
      dessert: false,
    },
    // Availability
    availability: { available: false, unavailable: false },
  },
  searchTerm: '',
};

export const itemGrid = {
  state: initialState,

  reducers: {
    setFilterState(
      state: IItemGrid,
      { headerName, chosenFilters }: { headerName: string; chosenFilters: { [key: string]: boolean } },
    ) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [headerName]: {
            ...chosenFilters,
          },
        },
      };
    },
    setSearchTerm(state: IItemGrid, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
  },

  selectors: {
    getState: () => (state: any) => state.itemGrid,
  },
};
