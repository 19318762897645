import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components';
import ReactSelect, { components, ControlProps, OptionTypeBase } from 'react-select';

const SelectLabel = styled.label<{ isFloating?: boolean }>`
  left: 1.25rem;
  position: absolute;
  transition: 0.2s ease all;
  color: ${({ theme }) => theme.color.dark['0']};
  font-weight: normal;
  top: 0.95rem;
  font-size: ${({ theme }) => theme.scale(0)};

  &[data-collapsed='true'] {
    top: 0.4rem;
    font-size: ${({ theme }) => theme.scale(-1)};
  }
`;

const Control = (controlProps: ControlProps<OptionTypeBase>) => (
  <>
    <components.Control {...controlProps} />
    <SelectLabel data-collapsed={controlProps.isFocused || controlProps.hasValue} htmlFor={controlProps.selectProps.id}>
      {controlProps.selectProps.label}
    </SelectLabel>
  </>
);

export const Select: FC<ComponentProps<typeof ReactSelect>> = (props) => (
  <ReactSelect
    {...props}
    role="listbox"
    placeholder=""
    components={{
      Control,
      IndicatorSeparator: () => null,
    }}
    styles={{
      dropdownIndicator: (styles) => ({ ...styles, color: '#1c0b00' }),
      control: (styles) => ({ ...styles, padding: '0.5rem', borderRadius: '10px' }),
      valueContainer: (styles) => ({ ...styles, top: '0.3rem' }),
      ...props.styles, // eslint-disable-line react/destructuring-assignment
    }}
  />
);
