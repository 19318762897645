import React, { FC, useRef } from 'react';
import { useLocale } from 'src/locale';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Button, DatePicker } from 'src/components';
import { useAuthContext } from 'src/Auth';
import { toast } from 'react-toastify';

const exportOrdersEndpoint =  `${process.env.REACT_APP_API_ENDPOINT}/v1/orders/export`;

const StyledForm = styled.form`
  display: flex;
  padding-left: 2.5rem;
`;

const ExportCSVButtonStyled = styled(Button)`
  margin: 4.25rem 0rem;
  outline: none;
  position: relative;
  top: -5px;
`;

type InitialFormikValues = {
  from: Date | null;
  to: Date | null;
};

type Errors = {
  from: string;
  to: string;
};

export const OrderExportForm: FC = () => {
  const { formatMessage, locale, region } = useLocale();
  const { authClient } = useAuthContext();
  const token = authClient.getIdToken();
  const formRef = useRef<HTMLFormElement>(null);
  const initialFormValues: InitialFormikValues = {
    from: null,
    to: null,
  };

  const onSubmitHandler: (values: any, formikHelpers: any) => void = () => {
    formRef.current!.submit();
    toast.success(formatMessage({ id: 'orders.exportStarted' }));
  };

  const onValidateHandler: (props: InitialFormikValues) => Errors = ({ from, to }) => {
    const errors = {} as Errors;
    if (!from) {
      errors.from = formatMessage({ id: 'orders.required' });
    }
    if (!to) {
      errors.to = formatMessage({ id: 'orders.required' });
    } else if (to && from && from > to) {
      errors.to = formatMessage({ id: 'orders.invalidDateRange' });
    }
    return errors;
  };

  const isExportDisabled: (values: InitialFormikValues, isValid: boolean) => boolean = (
    { from, to },
    isValid,
  ) => {
    const disableExport = !from || !to || !isValid;
    return disableExport;
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmitHandler} validate={onValidateHandler}>
      {({ values, isValid, submitForm }) => {
        return (
          <StyledForm
            action={exportOrdersEndpoint}
            method="POST"
            ref={formRef}
          >
            <DatePicker id="from" label={formatMessage({ id: 'orders.fromDate' })} maxDateOfToday dateFormat="yyyy-MM-dd" />
            <DatePicker id="to" label={formatMessage({ id: 'orders.toDate' })} maxDateOfToday dateFormat="yyyy-MM-dd" />
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="region" value={region} />
            <input type="hidden" name="locale" value={locale} />
            <ExportCSVButtonStyled
              type="button"
              size="large"
              kind="secondary"
              disabled={isExportDisabled(values, isValid)}
              onClick={submitForm}
            >
              {formatMessage({ id: 'orders.exportButtonText' })}
            </ExportCSVButtonStyled>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
