import styled from 'styled-components';

type ButtonProps = {
  kind?: 'primary' | 'secondary';
  size?: 'small' | 'large';
};

export const Button = styled.button.attrs<ButtonProps, ButtonProps>((props) => ({
  'data-kind': props.kind || 'primary',
  'data-size': props.size || 'large',
  disabled: props.disabled || false,
  ...props,
}))`
  cursor: pointer;
  width: max-content;
  background-clip: padding-box;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 2px;
  border: 4px solid transparent; // allows room inside browser's focus outline for focus border styles
  border-radius: 35px;

  &[data-size='small'] {
    font-size: 14px;
    padding: ${(p) => p.theme.scale(-2)} ${(p) => p.theme.scale(1)};
  }

  &[data-size='large'] {
    font-size: 20px;
    padding: ${(p) => p.theme.scale(-1)} ${(p) => p.theme.scale(2)};
  }

  &[data-kind='primary'] {
    background-color: ${({ theme }) => theme.color.primary['0']};
    color: ${({ theme }) => theme.color.light['0']};

    &:hover:not(:active) {
      background-color: ${({ theme }) => theme.color.primary['-2']};
    }

    &:active {
      background-color: ${({ theme }) => theme.color.primary['0']};
    }

    &[disabled],
    &[disabled]:hover:not(:active) {
      background-color: ${({ theme }) => theme.color.light['-5']};
      color: ${({ theme }) => theme.color.dark['+7']};
    }
  }

  &[data-kind='secondary'] {
    background-color: ${({ theme }) => theme.color.light['0']};
    color: ${({ theme }) => theme.color.primary['0']};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.primary['0']};

    &:hover:not(:active) {
      background-color: ${({ theme }) => theme.color.primary['+8']};
    }

    &:active {
      background-color: ${({ theme }) => theme.color.primary['0']};
      color: ${({ theme }) => theme.color.light['0']};
    }

    &[disabled],
    &[disabled]:hover:not(:active) {
      background-color: ${({ theme }) => theme.color.light['0']};
      box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.light['-5']};
    }
  }

  &[disabled],
  &[disabled]:hover:not(:active) {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.dark['+7']};
  }

  &:focus:not(:active) {
    border: 4px solid ${({ theme }) => theme.color.primary['+6']};
  }
`;
