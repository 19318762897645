import React, { FC, useEffect } from 'react';
import { Portal } from 'react-portal';
import FocusLock from 'react-focus-lock';
import { rgba } from 'polished';
import styled from 'styled-components';
import { CloseFunctionalityWrapper } from '../CloseFunctionalityWrapper';

const sizeMap = {
  small: { minHeight: '300px', maxHeight: '300px', width: '300px' },
  medium: { minHeight: '350px', maxHeight: '650px', width: '350px' },
  large: { minHeight: '350px', maxHeight: '80vh', width: '570px' },
};

const ModalContainer = styled.div<{ size: IModalProps['size'] }>`
  width: ${({ size = 'large' }) => sizeMap[size].width};
  min-height: ${({ size = 'large' }) => sizeMap[size].minHeight};
  max-height: ${({ size = 'large' }) => sizeMap[size].maxHeight};
  background-color: ${({ theme }) => theme.color.light['0']};
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: 1.2rem;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.color.dark['0'], 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

interface IModalProps {
  isModalOpen: boolean;
  size?: 'small' | 'medium' | 'large';
  onDismiss?: () => void;
  alternateClosingDisabled?: boolean;
}

type ICloseFunctionalityWrapper = Parameters<typeof CloseFunctionalityWrapper>[0];
type IModalContainerWrapper = Omit<ICloseFunctionalityWrapper, 'dismissCallback'> & {
  dismissCallback?: ICloseFunctionalityWrapper['dismissCallback'];
};

const ModalContainerWrapper: FC<IModalContainerWrapper> = ({ children, dismissCallback, ...props }) => {
  if (dismissCallback) {
    return (
      <CloseFunctionalityWrapper dismissCallback={dismissCallback} {...props}>
        {children}
      </CloseFunctionalityWrapper>
    );
  }

  return <>{children}</>;
};

export const Modal: FC<IModalProps> = ({
  isModalOpen = false,
  size = 'large',
  children,
  onDismiss,
  alternateClosingDisabled = false,
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
  }, [isModalOpen]);

  return isModalOpen ? (
    <Portal>
      <ModalOverlay data-testid="modal-overlay">
        <ModalContainerWrapper dismissCallback={onDismiss} closingDisabled={alternateClosingDisabled}>
          <FocusLock returnFocus>
            <ModalContainer aria-modal="true" role="dialog" size={size} {...props}>
              {children}
            </ModalContainer>
          </FocusLock>
        </ModalContainerWrapper>
      </ModalOverlay>
    </Portal>
  ) : null;
};
