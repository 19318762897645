/* eslint-disable */
import { createModel } from '@rematch/core';
import { createSelector } from '@rematch/select';
import { MenuItemFragmentFragment, RestaurantFragmentFragment, RestaurantMenuItemFragmentFragment } from 'src/graphql';

type State = {
  getItemsResponse: readonly MenuItemFragmentFragment[];
  getRestaurantsResponse: readonly (RestaurantFragmentFragment & {
    menuPrices?: readonly RestaurantMenuItemFragmentFragment[];
  })[];
};

export const data = createModel({
  state: {
    getItemsResponse: [],
    getRestaurantsResponse: [],
  },
  reducers: {
    setItemResponse: (state: State, getItemsResponse: State['getItemsResponse']) => ({
      ...state,
      getItemsResponse,
    }),
    setRestaurantsResponse: (state: State, getRestaurantsResponse: State['getRestaurantsResponse']) => ({
      ...state,
      getRestaurantsResponse,
    }),
  },
  selectors: {
    /**
     * Restaurant fragments indexed by ID
     *
     * e.g. `restaurantMap[restaurantId]`
     **/
    getRestaurantMap: () =>
      createSelector(
        (state: { data: State }) => state.data,
        (state: State) =>
          state.getRestaurantsResponse.reduce<Record<string, RestaurantFragmentFragment>>(
            (map, restaurant) => ({ ...map, [restaurant.id]: restaurant }),
            {},
          ),
      ),

    /**
     * Restaurant prices indexed by restaurant ID and item ID
     *
     * e.g. `restaurantPriceMap[restaurantId][itemId]`
     **/
    getRestaurantPriceMap: () =>
      createSelector(
        (state: { data: State }) => state.data,
        (state: State) =>
          state.getRestaurantsResponse.reduce<Record<string, Record<string, RestaurantMenuItemFragmentFragment>>>(
            (map, restaurant) => ({
              ...map,
              [restaurant.id]: restaurant.menuPrices!.reduce((priceMap, menuPrice) => {
                return {
                  ...priceMap,
                  [menuPrice.item.id]: menuPrice,
                };
              }, {}),
            }),
            {},
          ),
      ),
    /**
     * MenuItems indexed by item ID
     *
     * e.g. `itemMap[itemId]`
     **/
    getItemMap: () =>
      createSelector(
        (state: { data: State }) => state.data,
        (state: State) =>
          state.getItemsResponse.reduce<Record<string, MenuItemFragmentFragment>>(
            (map, item) => ({ ...map, [item.id]: item }),
            {},
          ),
      ),
  },
});
